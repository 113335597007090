<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <ServerError v-if="ServerError" />
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
    />
    <v-layout  wrap justify-center py-5>
      <v-flex xs11 pt-5  >
        <span class="headingChurch"
          >User List</span
        >
      </v-flex>
      <!-- <v-flex xs12 sm2 md2>
        <v-select
          dense
          :items="items"
          v-model="userType"
          label="User Type"
          outlined
          item-text="name"
          item-value="value"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm2 md2 text-right pa-2>
        <v-btn
          tile
          class="px-5"
          small
          outlined
          :href="ipURL + '/admin/user/list/xlsx'"
          target="_blank"
          color="warning"
          style="text-transform: none"
        >
          <span
            style="font-size: 14px; font-family: poppinssemibold; color: #000"
          >
            Download Excel</span
          >
        </v-btn>
      </v-flex> -->
    </v-layout>
    <v-layout  wrap justify-center>
      <v-flex xs11>
        <v-data-table
          :headers="headers"
          :items="user"
          :items-per-page="limit"
          hide-default-footer
          class="elevation-1 .text--black"
          id="virtual-scroll-table"
        >
          <template v-slot:[`item.lastLogin`]="{ item }">
            <span v-if="item.lastLogin">{{
              item.lastLogin.slice(0, 10)
            }}</span>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <span v-if="item.role == 'User'">Student</span>
            <span v-else>{{ item.role }}</span>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <span v-if="item.phone"
              >{{ item.countryCode }} {{ item.phone }}</span
            >
          </template>
          <template v-slot:[`item._id`]="{ item }">
            <v-icon small color="red" class="ml-4" @click.stop="openDeleteDialog(item)">
              mdi-delete
            </v-icon>
          </template>
          
        </v-data-table>
      </v-flex>
    </v-layout>
    <!-- pagination -->
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 0">
          <v-pagination :length="pages" v-model="currentPage" color="primary white--text"
            circle></v-pagination>
        </div>
      </v-flex>
    </v-layout>

     <!-- delete dialog -->
     <v-dialog v-model="dialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class=" grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to delete this User? 
            <br />
            This action <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img src="@/assets/images/userb.png" width="40px" height="40px" class="dialog-icon" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn style="font-family: interBold;font-size:13px;color:white" color="#cf3a45"
          @click="dialog = false">Cancel</v-btn>
          <v-btn style="font-family: interBold;font-size:13px;color:white" color="blue darken-1"
          @click="confirmDelete">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      role:localStorage.getItem('role'),
      appLoading: false,
      ServerError: false,
      showSnackBar:false,
      timeout:5000,
      msg: "",
      deleteDialog: false,
      page: 1,
        currentPage: 1,
        pages: 0,
        limit: 10,
      user: [],
      dialog: false,
      itemToDelete: null,
      // items: [
      //   { name: "All", value: "" },
      //   { name: "Student", value: "Student" },
      //   { name: "Institution", value: "Institution" },
      // ],
      userType: "",
      keyword: "",
      headers: [
        { text: "Name", value: "name", },
        { text: "Email", value: "email",  },
        { text: "Phone", value: "phoneNumber",},
        { text: "Role", value: "role", },
        { text: "Last Login", value: "lastLogin",  },
        { text: "Actions", value: "_id", },
       
      ],
    };
  },
  watch: {
    currentPage() {
        this.getData();
      },
    userType() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
getData() {
              this.appLoading = true;
           //   const currentTime = new Date().toISOString(); // Get current time in ISO format
              axios({
                  url: "/user/list",
  
                  method: "GET",
                  headers: {
                      token: localStorage.getItem("token"),
                  },
                  params: {
                    limit: this.limit,
                    page: this.currentPage,
  
                  },
              })
                  .then((response) => {
                      this.appLoading = false;
                      this.user = response.data.data;
                      // this.pages = response.data.pages;
                      // this.totalData = response.data.count;
                      this.pages = Math.ceil(response.data.totalLength / this.limit);
                  })
                  .catch((err) => {
                      this.appLoading = false;
                      this.ServerError = true;
                      console.log(err);
                  });
          },


    info(id) {
      this.$router.push("/userDetails?id=" + id._id);
    },
    
    openDeleteDialog(item) {
        this.itemToDelete = item;
        this.dialog = true;
      },
      confirmDelete() {
        if (this.itemToDelete) {
          this.deleteItem(this.itemToDelete);
        }
        this.dialog = false;
      },
      deleteItem(r) {
        var data = { id: r._id };
        axios({
          url: "/user/delete/account",
          method: "POST",
          data: data,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      },
  },
};
</script>

